import { forwardRef } from "react";
import { Combobox, ICombobox } from "~/components/v2/Combobox";
import { cn } from "~/utils/cn";

export type SelectFieldProps = ICombobox & {
  label: string;
  error?: string;
  required?: boolean;
  startAdornment?: React.ReactNode;
  containerClassName?: string;
  innerContainerClassName?: string;
  disabledLabel?: string;
  collisionPadding?: number;
};

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      label,
      error,
      disabled,
      required,
      startAdornment,
      className,
      containerClassName,
      innerContainerClassName,
      disabledLabel,
      collisionPadding,
      onBlur,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={containerClassName}>
        <div
          className={cn(
            "flex flex-col gap-1",
            { "opacity-50": disabled },
            innerContainerClassName,
          )}
        >
          {label && (
            <span className="text-sm font-semibold">
              {label}
              {required && "*"}
            </span>
          )}
          <div className="flex flex-col">
            <div className="relative">
              <Combobox
                {...props}
                aria-label={label}
                searchPlaceholder="Search..."
                className={cn(
                  "peer h-[2.625rem] py-2",
                  {
                    "border-borders-input": !!props.value,
                    "border-borders-border": !props.value,
                    "border-error bg-error-40 text-error placeholder-shown:border-error":
                      !!error,
                    "pl-7": !!startAdornment,
                  },
                  className,
                )}
                icon={<i className="mi-chevron-down h-4 w-4" />}
                disabled={disabled}
                disabledLabel={disabledLabel}
                collisionPadding={collisionPadding}
              />
              <span
                className={cn(
                  "absolute left-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999]",
                  {
                    "text-error": !!error,
                  },
                )}
              >
                {startAdornment}
              </span>
            </div>
          </div>
          {error && (
            <span className="flex items-center gap-1 text-xs text-error">
              <i className="mi-circle-error"></i>
              {error}
            </span>
          )}
        </div>
      </div>
    );
  },
);

SelectField.displayName = "SelectField";
