import { Job, MilestoneType, Prisma, User } from "@prisma/client";
import { QueryFunctionContext } from "@tanstack/react-query";
import { PaginatedRequest } from "~/pages/api/job_openings/types";
import axios from "~/services/axios.service";
import { PaginatedResponse } from "~/types/api";

export interface JobOpeningResult {
  data: PaginatedResponse<
    Job & {
      user: User;
      totalApplicants: number;
      activeApplicants: number;
      milestones: {
        count: number;
        color: string;
        name: string;
        milestoneType: MilestoneType;
      }[];
      Pipeline?: Prisma.PipelineGetPayload<{
        include: { milestones: { include: { stages: true } } };
      }>;
    }
  >;
  message: string;
}

export const fetchJobOpenings = (
  { signal }: QueryFunctionContext,
  queryParams?: PaginatedRequest & { showPublic?: boolean },
) =>
  axios
    .get<JobOpeningResult>("/api/job_openings/paginated", {
      signal,
      params: {
        ...queryParams,
        filters: queryParams?.filters
          ? JSON.stringify(queryParams.filters)
          : undefined,
        show_public: queryParams?.showPublic,
      },
    })
    .then(({ data }) => data.data);
