"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { datadogRum } from "@datadog/browser-rum";
import { AppMetadata } from "~/types/auth";

let isDatadogInitialized = false;

function initializeDatadog() {
  if (isDatadogInitialized || process.env.NEXT_PUBLIC_DATADOG_ENABLED !== 'true') {
    console.info("Datadog RUM is either already initialized or disabled");
    return;
  }

  const url = process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL;
  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

  if (!applicationId || !clientToken || !url) {
    console.error("Missing Datadog RUM configuration");
    return;
  }

  console.info(`Initializing Datadog RUM for ${url}`);

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: `scalis-${process.env.NODE_ENV}`,
    env: process.env.NODE_ENV,
    sessionSampleRate: process.env.NODE_ENV === 'production' ? 100 : 10,
    sessionReplaySampleRate: process.env.NODE_ENV === 'production' ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [url],
  });

  isDatadogInitialized = true;
}

export default function DatadogInit() {
  initializeDatadog();

  const { user } = useUser();
  const app_metadata = user?.app_metadata as AppMetadata;

  if (isDatadogInitialized && user) {
    datadogRum.setUser({
      id: String(app_metadata?.id) ?? user.sub,
      role: app_metadata.role,
      name: user.email!,
      email: user.email!,
    });
  }

  return null;
}
