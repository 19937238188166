import { cloneElement } from "react";
import { cn } from "~/utils/cn";
import { IconKind, IconProps } from "./icon.types";
import { iconClassName } from "./icon.styles";

export const Icon: React.FC<IconProps> = ({
  IconElement,
  size = 16,
  className,
  ...props
}: IconProps) => {
  if (IconElement && props.kind === IconKind.normal) {
    return <IconElement size={size} className={className} {...props} />;
  }

  return (
    <button
      data-testid={props["data-testid"]}
      className={cn(iconClassName, className)}
      onClick={props.onClick}
      disabled={props.disabled}
      aria-disabled={props.disabled}
      aria-label={props?.["aria-label"]}
      type="button"
    >
      {props.icon ? (
        <i className={props.icon} />
      ) : (
        IconElement && cloneElement(
          <IconElement size={size} />,
          { ...props, 'arial-label': undefined }
        )
      )}
    </button>
  );
};
