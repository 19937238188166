"use client";

import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface SidebarStateContextProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SidebarStateContext = createContext<SidebarStateContextProps>({
  open: true,
  setOpen: () => {},
});

export const SidebarStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [hasSidebar, setHasSidebar] = useState<boolean>(true);

  return (
    <SidebarStateContext.Provider value={{ open, setOpen }}>
      {children}
    </SidebarStateContext.Provider>
  );
};

const useSidebarState = () => {
  const context = useContext(SidebarStateContext);
  if (context === undefined) {
    throw new Error(
      "useSidebarState must be used within a SidebarStateProvider",
    );
  }
  return context;
};

export default useSidebarState;
