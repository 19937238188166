import { cn } from "~/utils/cn";
import { ListStatusPlaceholderTemplateProps } from "./list-status-placeholder-template.types";
import { Button } from "../../Button";

export const ListStatusPlaceholderTemplate: React.FC<
  ListStatusPlaceholderTemplateProps
> = ({ className, Icon, title, message, actionButton }) => {
  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center",
        className,
      )}
    >
      <div className="mx-auto">
        <div className="flex size-24 items-center justify-center rounded-full bg-primary-background">
          <Icon className="fa-3x h-12 w-12" />
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-center text-xl/6 font-semibold text-typography-high-contrast">
          {title}
        </h2>
        <p className="mt-2 text-center text-typography-low-contrast">
          {message}
        </p>
      </div>
      {actionButton && (
        <div className="mt-8">
          <Button size="lg" onClick={actionButton.onClick}>
            {actionButton.title}
          </Button>
        </div>
      )}
    </div>
  );
};
