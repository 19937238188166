import { IconProps as TablerIconProps, Icon } from "@tabler/icons-react";

export enum IconKind {
  normal = "normal",
  button = "button",
}

interface IconBaseProps<K extends IconKind>
  extends CustomOmit<TablerIconProps, "onClick"> {
  kind?: K;
  IconElement?: Icon;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
  "aria-label"?: string;
  ["data-testid"]?: string;
}

type IconNormalProps = IconBaseProps<IconKind.normal>;

interface IconButtonProps extends IconBaseProps<IconKind.button> {}

export type IconProps = IconNormalProps | IconButtonProps;
