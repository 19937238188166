import React from "react";
import { ListStatusPlaceholderTemplate } from "../../template";
import { cn } from "~/utils/cn";
import Spinner from "~/components/spinner";
import { LoadingStateProps } from "./loading-state.types";

export const LoadingState: React.FC<LoadingStateProps> = ({
  message,
  title,
  className,
  actionButton,
}) => {
  return (
    <ListStatusPlaceholderTemplate
      className={className}
      Icon={({ className }) => (
        <Spinner className={cn("h-12 w-12", className)} />
      )}
      title={title ?? "Loading..."}
      message={message ?? "Please wait."}
      actionButton={actionButton}
    />
  );
};
