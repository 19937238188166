import winston, { format } from "winston";
import DatadogWinston from "datadog-winston";

const errorStackFormat = format(info => {
  if (info instanceof Error) {
    return Object.assign({}, info, {
      message: `${info.message}\n${info.stack}`,
      stack: info.stack,
    });
  }
  if (info.error && Object.keys(info.error).length > 0) {
    return Object.assign({}, info, {
      message: `${info.error.message}\n${info.error.stack}`,
      stack: info.error.stack,
    });
  }
  return info;
});

const transporters: any = [
  new winston.transports.Console({
    format: format.combine(format.colorize({ all: false }), format.simple()),
  }),
];

if (process.env.DATADOG_API_KEY) {
  const datadogTransport = new DatadogWinston({
    apiKey: process.env.DATADOG_API_KEY,
    hostname: process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL,
    service: `scalis-${process.env.NODE_ENV}`,
    ddsource: "scalis-io-nextjs",
    ddtags: `service:scalis-${process.env.NODE_ENV}, env:${process.env.NODE_ENV}, `,
  });
  transporters.push(datadogTransport);
}

const logger = winston.createLogger({
  format: format.combine(format.timestamp(), format.json(), errorStackFormat()),
  defaultMeta: { service: "scalis" },
  transports: transporters,
});

export default logger;
