"use client";

import { useRouter, useSearchParams } from "next/navigation";
import { ChangeEventHandler, useContext, useEffect, useState } from "react";
import { SearchBarContext } from "~/components/v2/jobs/SearchBar";
import { useGetPlaceDetails } from "./useGetPlaceDetails";
import { LocationValue } from "~/components/v2/Filter/types";
import { PlaceDetailsReturn } from "~/queries/fetchPlaceDetails";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";

const useSearchBar = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    search,
    employeeType,
    placeId,
    setSearch,
    setEmployeeType,
    setPlaceId,
  } = useContext(SearchBarContext);
  const { data: placeDetails } = useGetPlaceDetails(placeId ?? "");
  const [location, setLocation] = useState<LocationValue>();
  const initialSearch = searchParams?.get("search");
  const initialPosition = searchParams?.get("position");
  const initialPlaceId = searchParams?.get("place_id");

  useEffect(() => {
    setSearch(initialSearch ?? "");
    setEmployeeType(initialPosition ?? "");
    setPlaceId(initialPlaceId ?? "");
  }, [initialPlaceId, initialPosition, initialSearch]);

  useEffect(() => {
    if (!placeDetails) {
      setLocation(undefined);
      return;
    }
    const details = placeDetails as PlaceDetailsReturn;
    const { lat, lng } = details.geometry.location;
    const location = {
      description: details.formatted_address,
      location: { lat, lng },
      radius: 25,
    };
    setLocation(location);
  }, [placeDetails]);

  const onSearch = () => {
    const url = `/jobs?search=${encodeURIComponent(search ?? "")}&place_id=${encodeURIComponent(placeId ?? "")}&position=${encodeURIComponent(employeeType ?? "")}`;

    router.push(url);
  };

  const handleChangeLocation = (location: PlacesReturn) => {
    setPlaceId(location.place_id);
  };

  const handleChangeEmployeeType = (_: string, value: string) => {
    setEmployeeType(value);
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
  };

  return {
    search,
    employeeType,
    placeId,
    location,
    onSearch,
    handleChangeEmployeeType,
    handleChangeLocation,
    handleSearchChange,
  };
};

export default useSearchBar;
