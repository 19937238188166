import * as React from "react";
import { format } from "date-fns";

import { cn } from "~/utils/cn";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/v2/Popover";
import { Button } from "~/components/v2/Button";
import {
  DateRange,
  SelectRangeEventHandler,
  SelectSingleEventHandler,
} from "react-day-picker";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { DaySelector } from "../DaySelector";
import { enUS } from "date-fns/locale";

interface Props {
  className?: string;
}
interface IDatePicker extends Props {
  date?: Date;
  onSelect: SelectSingleEventHandler;
  mode?: "single";
}
interface IDateRangePicker extends Props {
  date?: DateRange;
  onSelect: SelectRangeEventHandler;
  mode: "range";
}

export function DatePicker({
  date,
  onSelect,
  mode = "single",
  className,
}: IDatePicker | IDateRangePicker) {
  const formatSingleDate = (date: Date) =>
    date ? (
      format(date, "MMM d,yyyy", { locale: enUS })
    ) : (
      <span>Pick a date</span>
    );

  const formatRangeDate = (date: DateRange) =>
    date && date.from && date.to ? (
      `${format(date.from, "PP")} - ${format(date.to, "PP")}`
    ) : (
      <span>Pick a date</span>
    );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "justify-start text-left font-normal text-black hover:bg-white active:bg-white",
            !date && "text-muted-foreground",
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {mode === "single"
            ? formatSingleDate(date as Date)
            : formatRangeDate(date as DateRange)}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto bg-white p-0" align="start">
        <DaySelector
          mode={mode}
          selected={date as any}
          // @ts-ignore
          onSelect={onSelect}
          disabled={(date) => date < new Date("1900-01-01")}
          initialFocus
          numberOfMonths={mode === "range" ? 2 : 1}
        />
      </PopoverContent>
    </Popover>
  );
}
