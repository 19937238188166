import { QueryFunctionContext } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { AvatarType, fetchAvatar } from "~/queries/fetchAvatar";
import axios from "~/services/axios.service";
import { UserWithNylasAccount } from "~/services/user.service";
import logger from "~/utils/logger";
import { useUpdateJob } from "../jobs/useUpdateJob";
import { useGetAvatar } from "../useGetAvatar";
import { useMockedSession } from "../useMockedSession";

export interface CompanyEmployee {
  profile_image: string;
  value: string;
  label: string;
  role: "recruiter" | "manager" | "interviewer";
  nylas_account?: boolean;
}

export interface RecruiterPanel {
  recruiter: CompanyEmployee;
  manager: CompanyEmployee;
  interviewer: CompanyEmployee[];
}

const MAX_HR_NUMBER = 10;

export const useRecruiterTeam = (recruiterTeam?: CompanyEmployee[]) => {
  const session = useMockedSession();
  const email = session?.user?.email || "";
  const [companyTeam, setFoundCompanyTeam] = React.useState<CompanyEmployee[]>(
    recruiterTeam || [],
  );
  // Render just the first time
  const renderFirstTime = React.useRef(true);
  const [recruiterPanel, setRecruiterPanel] = React.useState<RecruiterPanel>();
  const { mutateAsync: update } = useUpdateJob();

  const getCompanyTeam = async () => {
    if (!email) return;
    if (companyTeam[0]?.value) return;
    await axios
      .get(`/api/company/employee/all?email=${email}`)
      .then(({ data }) => {
        const { employees } = data as { employees: UserWithNylasAccount[] };
        setFoundCompanyTeam(() =>
          employees.map(employee => {
            return {
              value: String(employee.id || 0),
              label: employee.full_name || "",
              profile_image: employee.profile_image || "",
              role: "interviewer",
              nylas_account: !!employee?.nylas_account,
            };
          }),
        );
      });
  };

  useEffect(() => {
    if (renderFirstTime.current) {
      renderFirstTime.current = false;
      return;
    }

    getCompanyTeam();
  }, [session]);

  const [interviewer, setInterviewer] = React.useState<CompanyEmployee[]>([
    {
      profile_image: "",
      value: "",
      label: "",
      role: "interviewer",
    },
  ]);
  const [manager, setManager] = React.useState<CompanyEmployee>({
    profile_image: "",
    value: "",
    label: "",
    role: "manager",
  });
  const [recruiter, setRecruiter] = React.useState<CompanyEmployee>({
    profile_image: "",
    value: "",
    label: "",
    role: "recruiter",
  });

  const { data: recruiterAvatar } = useGetAvatar(
    Number(recruiter?.value),
    AvatarType.companyUser,
  );
  const { data: managerAvatar } = useGetAvatar(
    Number(manager?.value),
    AvatarType.companyUser,
  );

  const handleAddInterviewer = () => {
    const pastList = [...interviewer];
    if (pastList.length === companyTeam.length) return;
    pastList.push({
      value: "",
      label: "",
      profile_image: "",
      role: "interviewer",
    });
    setInterviewer(pastList);
  };

  const handleRemoveInterviewer = (value?: string) => {
    if (!value) {
      setInterviewer(interviewer.slice(0, interviewer.length - 1));
      return;
    }
    setInterviewer(
      interviewer.filter(interviewer => interviewer.value !== value),
    );
  };

  const addFieldInformation = async (
    field: string,
    index: number,
    value: string,
  ) => {
    const foundUser = companyTeam.filter(
      interviewer => interviewer.value === value,
    )[0];
    switch (field) {
      case "recruiter":
        setRecruiter({
          profile_image: foundUser.profile_image,
          value: foundUser.value,
          label: foundUser.label,
          role: "recruiter",
        });
        break;
      case "manager":
        setManager({
          profile_image: foundUser.profile_image,
          value: foundUser.value,
          label: foundUser.label,
          role: "manager",
        });
        break;
      case "interviewer":
        const pastList = [...interviewer];
        const profile_image = await fetchAvatar({} as QueryFunctionContext, {
          id: parseInt(foundUser?.value),
          type: AvatarType.companyUser,
        });
        pastList[index] = {
          profile_image: profile_image || "",
          value: foundUser.value,
          label: foundUser.label,
          role: "interviewer",
        };
        setInterviewer(pastList);
        break;
    }
  };

  const saveNewRecruiterPanel = async (jobId: number) => {
    const newRecruiterPanel = [...interviewer, ...[recruiter], ...[manager]];
    const id = session?.data?.user.id || 0;
    await update({
      jobId: jobId,
      data: {
        recruiter_panel: newRecruiterPanel,
        assignedBy: id,
        assignedTo: parseInt(recruiter.value),
        assignedTo_name: recruiter.label,
      },
    }).catch(error => {
      logger.error(`Got error on update: ${error}`);
    });
    await getRecruiterPanel(jobId);
  };

  const getRecruiterPanel = async (jobId: number) => {
    if (!jobId) return;
    await axios.get(`/api/job/with-company/${jobId}/`).then(({ data }) => {
      const { data: job } = data;
      const { recruiter_panel: recruiterPanel } = job as {
        recruiter_panel: CompanyEmployee[];
      };
      if (!recruiterPanel) return;
      const interviewer = recruiterPanel.filter(
        employee => employee.role === "interviewer",
      );
      const recruiter = recruiterPanel.filter(
        employee => employee.role === "recruiter",
      )[0];
      const manager = recruiterPanel.filter(
        employee => employee.role === "manager",
      )[0];
      setInterviewer(interviewer);
      setRecruiter(recruiter);
      setManager(manager);
      setRecruiterPanel({
        interviewer,
        recruiter,
        manager,
      });
    });
  };

  const updatedManager = { ...manager, profile_image: managerAvatar ?? "" };
  const updatedRecruiter = {
    ...recruiter,
    profile_image: recruiterAvatar ?? "",
  };

  const getFilteredHrPeople = () => {
    const allEmployers = [...interviewer];

    if (updatedManager.label) {
      allEmployers.unshift(updatedManager);
    }

    if (updatedRecruiter.label) {
      allEmployers.unshift(updatedRecruiter);
    }

    return allEmployers?.slice(0, MAX_HR_NUMBER);
  };

  return {
    companyTeam,
    interviewer,
    manager: updatedManager,
    recruiter: updatedRecruiter,
    saveNewRecruiterPanel,
    handleAddInterviewer,
    getRecruiterPanel,
    handleRemoveInterviewer,
    addFieldInformation,
    recruiterPanel,
    filteredHrPeople: getFilteredHrPeople(),
  };
};
