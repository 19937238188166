"use client";

import {
  BriefcaseIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import {
  FC,
  FormEventHandler,
  PropsWithChildren,
  createContext,
  useState,
} from "react";
import useSearchBar from "~/hooks/useSearchBar";
import { cn } from "~/utils/cn";
import { Button } from "../Button";
import LocationField from "../LocationField";
import { SelectField } from "../SelectField";
import { TextField } from "../TextField";

export const SearchBarContext = createContext<{
  search?: string;
  employeeType?: string;
  placeId?: string;
  setSearch: (search?: string) => void;
  setEmployeeType: (employeeType?: string) => void;
  setPlaceId: (placeId?: string) => void;
  //@ts-ignore
}>(null);

export const SearchBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [search, setSearch] = useState<string>();
  const [employeeType, setEmployeeType] = useState<string>();
  const [placeId, setPlaceId] = useState<string>();
  return (
    <SearchBarContext.Provider
      value={{
        search,
        employeeType,
        placeId,
        setSearch,
        setEmployeeType,
        setPlaceId,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
};

interface Props {
  isNavbar?: boolean;
  isOpen?: boolean;
}

const SearchBar: FC<Props> = ({ isNavbar, isOpen }) => {
  const [open, setOpen] = useState(isOpen || false);
  const {
    search,
    employeeType,
    handleChangeEmployeeType,
    handleSearchChange,
    handleChangeLocation,
    location,
    onSearch,
  } = useSearchBar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch: FormEventHandler = e => {
    e.preventDefault();
    onSearch();
    handleClose();
  };

  return (
    <form
      onSubmit={handleSearch}
      className={cn(
        "w-full bg-white transition-all duration-200",
        !isNavbar ? "px-5 py-4" : "py-1",
      )}
    >
      <div
        className={cn(
          "mx-auto flex max-w-[60rem] flex-col gap-2 lg:flex-row lg:gap-4",
          {
            "gap-0": !open,
          },
        )}
      >
        <TextField
          className="rounded-full pl-9 hover:bg-primary-40 focus:border-[1.5px] focus:border-primary focus-visible:ring-0"
          containerClassName="flex-1"
          placeholder={"Job Title / Keyword Designer"}
          startAdornment={<MagnifyingGlassIcon className="h-5 w-5" />}
          onClick={handleOpen}
          value={search}
          onChange={handleSearchChange}
        />
        <div
          className={cn(
            "flex h-[8.75rem] flex-col gap-2 overflow-hidden transition-all lg:h-full lg:min-w-[31.5rem] lg:flex-row lg:gap-4",
            { "h-0 lg:h-full": !open },
          )}
        >
          <LocationField
            name="location"
            label=""
            value={location?.description ?? ""}
            setValue={handleChangeLocation}
            className="min-w-[13.5rem] rounded-full pl-9 hover:bg-primary-40 focus:outline-none "
            containerClassName="flex flex-1"
            placeholder="Location"
            startAdornment={<MapPinIcon className="h-5 w-5" />}
          />
          <SelectField
            options={[
              { label: "Full Time", value: "FULL_TIME" },
              { label: "Part Time", value: "PART_TIME" },
              { label: "Seasonal", value: "SEASONAL" },
              { label: "Contractor", value: "CONTRACTOR" },
              { label: "Internship", value: "INTERNSHIP" },
              { label: "Per-Diem", value: "PER_DIEM" },
              { label: "Temporary", value: "TEMPORARY" },
              { label: "Other", value: "OTHER" },
            ]}
            label=""
            multi={false}
            emptyTerm="type"
            name="employee_type"
            searchPlaceholder="Search employee type"
            value={employeeType || ""}
            setValue={handleChangeEmployeeType}
            className="min-w-full whitespace-nowrap rounded-full border-white-90 pl-9 text-white-85 hover:bg-primary-40 focus:outline-none"
            innerContainerClassName="w-full"
            containerClassName="flex flex-1"
            placeholder="Employee type"
            startAdornment={<BriefcaseIcon className="h-5 w-5 text-white-85" />}
          />
          <Button
            className="hidden h-10 w-10 rounded-full px-2 lg:block"
            type="submit"
          >
            <MagnifyingGlassIcon className="h-6 w-6" />
          </Button>
          <div className="flex justify-between lg:hidden">
            <Button
              onClick={handleClose}
              className="h-10 w-[7.5rem]"
              variant="outline"
              type="button"
            >
              Cancel
            </Button>
            <Button type="submit" className="h-10 w-[7.5rem]">
              Search jobs
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
