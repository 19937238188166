import { Job, MilestoneType, Prisma, User } from "@prisma/client";
import { useQuery } from "@tanstack/react-query";
import { PaginatedRequest } from "~/pages/api/job_openings/types";
import { fetchJobOpenings } from "~/queries/jobOpening/fetchJobOpenings";
import { PaginatedResponse } from "~/types/api";

export const useGetJobOpenings = (
  queryParams?: PaginatedRequest & { showPublic?: boolean; search?: string },
  initialData?: PaginatedResponse<
    Job & {
      user: User;
      totalApplicants?: number;
      activeApplicants?: number;
      milestones?: {
        count: number;
        color: string;
        name: string;
        milestoneType: MilestoneType;
      }[];
      Pipeline?: Prisma.PipelineGetPayload<{
        include: { milestones: { include: { stages: true } } };
      }>;
    }
  >,
  enabled?: boolean,
) =>
  useQuery({
    queryKey: ["job-openings", queryParams?.search],
    queryFn: (params) => fetchJobOpenings(params, queryParams),
    initialData,
    enabled,
  });
