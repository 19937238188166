import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { DayPicker } from "react-day-picker";

import { cn } from "~/utils/cn";

export type DaySelectorProps = React.ComponentProps<typeof DayPicker>;

function DaySelector({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: DaySelectorProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3 text-dark-color-text-100", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center h-min",
        caption_label: "text-sm font-medium mt-1",
        nav: "space-x-1 flex items-center mt-1",
        nav_button: cn(
          "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm  hover:text-accent-foreground h-9 px-4 py-2 ",
          "h-9 w-9 bg-transparent p-0 opacity-100 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1 text-[red]",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-typography-high-contrast rounded-md w-8 font-semibold text-sm text-[0.8rem] mt-3",
        row: "flex w-full mt-2",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-background-primarybackground hover:text-accent-foreground h-9 px-4 py-2",
          "h-8 w-8 p-0 font-normal bg-red aria-selected:opacity-100",
        ),
        day_selected:
          "!bg-primary text-white hover:bg-secondary-background-hover hover:text-white focus:bg-primary focus:text-white",
        day_today:
          "bg-primary-background text-dark-color-text-100 hover:bg-primary-background-hover hover:text-dark-color-text-100",
        day_outside: "text-white-85",
        day_disabled: "text-white-85",
        day_range_middle:
          "aria-selected:bg-white-95 aria-selected:text-dark-color-text-100 rounded-none",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <ChevronLeftIcon className="w-4 h-4 text-typography-high-contrast" />
        ),
        IconRight: ({ ...props }) => (
          <ChevronRightIcon className="w-4 h-4 text-typography-high-contrast" />
        ),
      }}
      {...props}
    />
  );
}
DaySelector.displayName = "Day Selector";

export { DaySelector };
