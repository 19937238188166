"use client";

import { SidebarStateProvider } from "~/hooks/useSidebarState";
import { TooltipProvider } from "~/components/v2/Tooltip";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { SearchBarProvider } from "~/components/v2/jobs/SearchBar";

import {
  isServer,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AdvanceCandidateProvider } from "~app/company/(dashboard)/pipeline/components/advance-candidate-context";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

interface ProvidersProps {
  children: React.ReactNode;
}

export default function Providers({ children }: ProvidersProps) {
  const queryClient = getQueryClient();

  return (
    <SidebarStateProvider>
      <TooltipProvider>
        <UserProvider>
          <SearchBarProvider>
            <QueryClientProvider client={queryClient}>
              <AdvanceCandidateProvider>{children}</AdvanceCandidateProvider>
            </QueryClientProvider>
          </SearchBarProvider>
        </UserProvider>
      </TooltipProvider>
    </SidebarStateProvider>
  );
}
